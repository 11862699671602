body {
  font-size: 14px;
  font-weight: 400;
  padding-top: 100px;
  color: #3c3c3c;
  font-family: 'Poppins', sans-serif !important;
}

a,
a:hover {
  text-decoration: unset !important;
  color: unset !important;
}

.header-fix {
  height: 100px;
  padding: 24px 5px;
}

.bg-light-wh {
  background: #f5f5f5;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.15);
  z-index: 4;
}

.bg-img {
  background-image: url('../../../../../public/Images/img_nav/Saint Valentin.png');

  background-size: contain;

  background-repeat: repeat-x;
}

.bg-img-1 {
  background-image: url('../../../../../public/Images/img_nav/Saint Valentin.png');

  background-size: cover;

  background-repeat: repeat-x;
}

/* fete de la musique */
/* .bg-img {
  background-image: url('../../../../../public/Images/img_nav/Fete de la musique-m.png'),
    url('../../../../../public/Images/img_nav/Fete de la musique.png'),
    url('../../../../../public/Images/img_nav/Fete de la musique-m.png');
  background-position: 0% 50%, 50% 50%, 100% 50%;
  background-repeat: no-repeat;

  background-size: 30% 100%, 40% 100%, 30% 100%;
} */

.bg-img-new-year {
  background-image: url('../../../../../public/Images/img_nav/patterns.png'),
    url('../../../../../public/Images/img_nav/Nouvel-an.png'),
    url('../../../../../public/Images/img_nav/patterns.png');
  background-position: 0% 50%, 50% 50%, 100% 50%;
  background-repeat: no-repeat;

  background-size: 30% 100%, 40% 100%, 30% 100%;
}

.bg-img-new-year-admin {
  background-image: url('../../../../../public/Images/img_nav/Nouvel-an.png'),
    url('../../../../../public/Images/img_nav/patterns.png');
  background-position: 0% 50%, 100% 50%;
  background-repeat: no-repeat;
  background-size: 50% 100%, 50% 100%;
}

.bg-img-new-year-1 {
  background-image: url('../../../../../public/Images/img_nav/patterns.png');

  background-size: cover;

  /* background-repeat: repeat-x; */
}
.logo-m {
  height: 47px;
  margin-top: -1px;
}

.box-menu-list {
  display: inline-flex;
  justify-content: flex-end;
  align-items: stretch;
  justify-items: center;
  padding-right: 27px;
}

.box-menu-list .btn-menu-icon {
  height: 52px;
  margin-left: 15px;
}

.box-menu-list .btn-menu-icon > .btn-menu-list {
  background-color: #fff;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  color: #3c3c3c;
  border-radius: 48px;
  font-size: 13px;
  height: 52px;
  cursor: pointer;
  display: flex;
  align-self: auto;
  min-height: auto;
  align-items: center;
  justify-content: center;
  padding: 14px 18px;
  text-align: center;
}

.box-menu-list .btn-menu-icon > .btn-menu-list:hover,
.box-menu-list .btn-menu-icon > .btn-menu-list.active,
.btn-menu-icon:hover > .btn-menu-list {
  background: #ffe426;
}

.box-menu-list .btn-menu-icon > .box-icon-menu {
  background-size: contain;
  padding: 0px !important;
  width: 52px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none;
  border-radius: unset;
  height: 52px;
  cursor: pointer;
}

.menu-list {
  min-width: 221px !important;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 0px;
  padding: 0px !important;
}

.menu-list li:first-child {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.menu-list li .dropdown-item {
  padding: 4px 16px;
  font-size: 14px;
  letter-spacing: -0.32px;
  text-align: left;
  color: #3c3c3c;
}

.menu-list li.first-child .dropdown-item:hover {
  color: #3c3c3c;
  background-color: rgba(255, 228, 38, 0.4);
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.menu-list li.last-child .dropdown-item:hover {
  color: #3c3c3c;
  background-color: rgba(255, 228, 38, 0.4);
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.dropdown-menu.menu-list-sm {
  min-width: 130px !important;
}

.bt-icon-alert {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Notif_Default.png');
}

.bt-icon-alert:hover,
.bt-icon-alert.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Notif_Active.png');
}

.bt-icon-user {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Profile_Default.png');
}

.bt-icon-user:hover,
.bt-icon-user.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Profile_Active.png');
}

.bt-icon-video {
  background-image: url('../../../../../public/Images/icon-menu/Menu_icon_video-v2.png');
}

.bt-icon-video:hover,
.bt-icon-video.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_icon_video_inactive_v2.png');
}

.box-menu-left {
  position: fixed;
  left: 0%;
  top: 0%;
  bottom: 0%;
  background: #f5f5f5;
  border-radius: 0px;
  width: 100px;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.15) !important;
  z-index: 9;
  padding-top: 115px;
}

.box-sub-menu {
  display: none;
  position: absolute;
  width: 207px;
  box-shadow: 3px 7px 3px rgba(0, 0, 0, 0.15) !important;
  background: #f5f5f5;
  border-radius: 0px;
  top: 99px;
  left: 83px;
  height: 100%;
  z-index: 25;
  transition: transform 0.35s;
}

.box-sub-menu.active {
  display: block;
}

.box-subtext-menu {
  margin: 35px 15px 0px 10px;
}

.box-text-menu {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 43px;
  text-align: left;
}

.box-radius,
.box-radius-2,
.box-radius-3 {
  display: block;
  position: absolute;
  left: 0;
  width: 125px;
  height: 109px;
  top: 0px;
  background-color: #f5f5f5;
  z-index: 20;
}

.box-radius {
  padding: 24px 15px;
  margin-left: 0px;
}

.box-radius-2 {
  width: 35px;
  height: 20px;
  left: 100px;
  top: 99px;
  background: #fff;
  -webkit-box-shadow: inset 9px 11px 8px -7px rgb(217 217 217);
  -moz-box-shadow: inset 9px 11px 8px -7px rgb(217 217 217);
  box-shadow: inset 9px 10px 8px -7px rgb(217 217 217);
  border-radius: 12px 0px 0px 0px;
}

.box-radius-2.expand {
  left: 250px;
  top: 100px;
  z-index: 28 !important;
}

.box-radius-3 {
  opacity: 0;
  background-color: whitesmoke;
  z-index: 26;
  left: 240px;
  top: 98px;
  width: 20px;
  height: 20px;
}

.box-radius-3.active {
  opacity: 1;
}

.wrapper-box-menu-left {
  text-align: center;
}

.box-menu-block {
  width: 52px;
  height: 52px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  margin-bottom: 15px;
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.box-menu-block-faq {
  width: 52px;
  height: 52px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  margin-bottom: 15px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.bt-icon-dashboard {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Dashboard_Default.png');
}

.bt-icon-dashboard:hover,
.bt-icon-dashboard.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Dashboard_Active.png');
}

.bt-icon-non-attr {
  background-image: url('../../../../../public/Images/icon-menu/Menu_NonAttr_Default.png');
}

.bt-icon-non-attr:hover,
.bt-icon-non-attr.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_NonAttr_Active.png');
}

.bt-icon-encour {
  background-image: url('../../../../../public/Images/icon-menu/Menu_EnCours_Default.png');
}

.bt-icon-analyzer {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Analyze_Default_inactive-v2.png');
}

.bt-icon-analyzer:hover,
.bt-icon-analyzer.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Analyze_Default_active-v2.png');
}

.bt-icon-faq {
  background-image: url('../../../../../public/Images/icon-menu/faq-icon-incative-v2.png');
  object-fit: contain;
}
.bt-icon-faq:hover,
.bt-icon-faq.active {
  background-image: url('../../../../../public/Images/icon-menu/faq-icon-active-v2.png');
}

.bt-icon-encour:hover,
.bt-icon-encour.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_EnCours_Active.png');
}

.bt-icon-enretard {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Retard_Default.png');
}

.bt-icon-enretard:hover,
.bt-icon-enretard.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Retard_Active.png');
}

.bt-icon-validee {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Validee_Default.png');
}

.bt-icon-validee:hover,
.bt-icon-validee.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Validee_Active.png');
}

.bt-icon-redac {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Redacteur_Default.png');
}

.bt-icon-redac:hover,
.bt-icon-redac.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Redacteur_Active.png');
}

.bt-icon-util {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Utilisateur_Default.png');
}

.bt-icon-util:hover,
.bt-icon-util.active {
  background-image: url('../../../../../public/Images/icon-menu/Menu_Utilisateur_Active.png');
}

@media screen and (max-width: 768px) {
  .wrapper-content {
    padding: 40px 20px 50px 120px !important;
    width: 100%;
  }

  .logo-m {
    height: 25px !important;
    margin-top: 8px !important;
  }
}
